<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <header class="titulo h4-responsive text-center text-md-left m-2 ml-md-5 pl-md-4 pl-lg-0 m-lg-3">
        Filtros de búsqueda
      </header>
      <div class="d-flex flex-wrap ml-md-4 pl-md-4 pl-lg-0 ml-lg-0">
        <div class="col-12 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaDespacho"
            type="month"
            label="Mes"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaDel"
            type="date"
            label="Desde"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            outline
          />
        </div>
        <div :class="['col text-center px-0']">
          <mdb-btn
            flat
            dark-waves
            icon="broom"
            class="my-1 px-3"
            @click="restablecerFiltros"
            >
            Restablecer filtros
          </mdb-btn>
        </div>
      </div>
      <article class="mx-2 mx-md-3">
        <div
          v-if="!Object.keys(despachos).length"
          class="mensaje-no-items pb-3"
        >
          <font-awesome-icon
            icon="calendar-times"
            size="5x"
            class="icono"
          />
          <p class="texto">No hay despachos en esta fecha</p>
        </div>
        <ul v-else class="table despachos">
          <li class="encabezado sticky">
            <div class="thead claro">
              <div class="col-12 col-md-2">
                <span class="text-muted">
                  ID -
                </span>
                Transportista
              </div>
              <div class="col-12 col-md-2">
                Fecha del despacho
                <span class="small-block">
                  Fecha del retorno
                </span>
              </div>
              <div class="col-12 col-md-3">
                Zonas
              </div>
              <div class="col-12 col-md-2 text-right">
                Estatus
                <span class="small-block">
                  Peso
                </span>
              </div>
              <div class="col-12 col-md text-center">Acciones</div>
            </div>
          </li>
          <li
            class="contenido cursor-pointer"
            v-for="d in despachos"
            :key="d.id_des"
            @click="$router.push(`/despacho/${d.id_des}`)"
          >
            <div
              class="col-12 col-md-2"
              data-columna="ID - Transportista"
            >
              <div>
                <span
                  title="Número de despacho"
                  class="text-muted"
                >
                  {{d.id_des}} -
                </span>
                {{d.nom_per}} {{d.ape_per}}
              </div>
            </div>
            <div
              class="col-12 col-md-2"
              data-columna="Fecha despacho Fecha retorno"
            >
              <div>
                {{formatearFechaYHora(d.fec_cre_des)}}
                <span
                  title="Número de despacho"
                  class="small-block"
                >
                  {{formatearFecha(d.fec_ret_des)}}
                </span>
              </div>
            </div>
            <div
              class="col-12 col-md-3 col-lg-3"
              data-columna="Zonas"
            >
              <div>
                <span class="small-block">
                  <span class="auto-coma text-capitalize">
                    {{ d.zonas.toLowerCase() }}
                  </span>
                </span>
              </div>
            </div>
            <div
              class="col-12 col-md-2 text-md-right"
              data-columna="Peso"
            >
              <div>
                <span :class="['bold text-capitalize d-none d-md-block', asignarColorEstado(d.est_des)]">
                  {{ d.est_des }}
                </span>
                <span class="small-block">
                  {{convertirValorAFixed(d.pes_des)}} KG
                </span>
              </div>
            </div>
            <div
              class="col-12 d-md-none"
              data-columna="Estatus"
            >
              <div>
                <span :class="['bold text-capitalize', asignarColorEstado(d.est_des)]">
                  {{ d.est_des }}
                </span>
              </div>
            </div>
            <div
              class="col-12 col-md text-md-center"
              data-columna="Acciones"
              @click.stop
            >
              <mdb-btn
                dark-waves
                flat
                icon="dolly"
                class="mx-0 my-1 px-2"
                :disabled="d.est_des == 'Cargando'"
                @click="verificacionDes(d.fec_mod_des, d.id_des, d.est_des, d.obs_des)"
              >
                Devolver
              </mdb-btn>
            </div>
          </li>
        </ul>
        <div
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </div>
      </article>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      :mensaje="`El despacho ${idDesDev} pasará a estatus Cargando`"
      textoBotonConfirmar="Devolver"
      @cerrar="modalConfirmacionEliminar = false, idDesDev = '', obsDesDev = ''"
      @confirmar="devolverDespacho"
    />
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import {
  mdbBtn,
  mdbInput
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import { convertirNumeroAFixed, formatearFecha, formatearFechaYHora, asignarColorEstado, comprobarRutaHija } from '@/funciones/funciones.js'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Devolucion',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje,
    ConfirmacionEliminar,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      formatearFecha,
      formatearFechaYHora,
      cargando: false,
      alertaMensaje: {
        contenido: ''
      },
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      despachos: {},
      fechaDespacho: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      fechaDel: '',
      fechaHasta: '',
      idDesDev: '',
      timeout: null,
      obsDesDev: ''
    }
  },
  mounted () {
    this.cargaInicial()
  },
  watch: {
    fechaDespacho () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.cargaInicial()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.cargaInicial()
    }
  },
  methods: {
    convertirValorAFixed (numero) {
      return convertirNumeroAFixed(numero)
    },
    cargaInicial () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.cargando = true
        this.resultados = {}
        let data = this.fechaDespacho ? 'y=' + this.fechaDespacho.split('-')[0] + '&m=' + this.fechaDespacho.split('-')[1] + '&' : ''
        data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta + '&' : ''
        apiPost({ s: 'despachosMv' }, data)
          .then((res) => {
            this.despachos = res.data.des
            this.cargando = false
          })
      }, 500) // delay
    },
    verificacionDes (fecha, id, est, obs) {
      if (est !== 'Cargando') {
        const [FECHA] = fecha.slice(0, 16).split(' ')
        const com = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)) + '-' + ('0' + (new Date().getDate()))
        const fecha1 = new Date(FECHA)
        const fecha2 = new Date(com)
        const diferencia = fecha2.getTime() - fecha1.getTime()
        const diasDeDiferencia = diferencia / 1000 / 60 / 60 / 24
        if (diasDeDiferencia <= 2) {
          this.idDesDev = id
          this.obsDesDev = obs.includes('null') ? 'Despacho devuelto' : obs + '. Despacho devuelto'
          this.modalConfirmacionEliminar = true
        } else {
          this.alertaMensaje = {
            contenido: 'Han transcurrido más de dos días desde que se despachó',
            tipo: 'advertencia'
          }
        }
      } else {
        this.alertaMensaje = {
          contenido: 'El despacho ya se encuentra en estado "Cargando"',
          tipo: 'advertencia'
        }
      }
    },
    devolverDespacho () {
      apiPost({ s: 'desDevolverMv' }, 'id=' + this.idDesDev + '&obs= ' + this.obsDesDev)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.idDesDev = ''
            this.obsDesDev = ''
            this.cargaInicial()
          }
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un problema devolviendo el despacho',
            tipo: 'error'
          }
        })
    },
    restablecerFiltros () {
      this.fechaDespacho = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
      this.fechaDel = ''
      this.fechaHasta = ''
      this.cargaInicial()
    }
  },
  computed: {
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    }
  }
}
</script>

<style lang="scss" scoped>
.table.despachos {
  margin: 0 auto 3rem;
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("768px");
}
</style>
